<template>
  <div id="public-search">
    <div class="header">
      <div>
        <h1>
          SONG SEARCH
        </h1>

        <md-field>
          <label for="song_title">SONG TITLE</label>
          <md-input id="song_title" v-model="search.title" />
        </md-field>

        <md-field>
          <label for="writer">SONGWRITER(S)</label>
          <md-input id="writer" v-model="search.writer" />
        </md-field>

        <p class="legal">
          By clicking "Search" below, you, both individually and acting on
          behalf of any organization may represent in connection with your use
          of Songfile: (1) represent and warrant that you have the authority to
          enter into this agreement on behalf of any such organization; (2)
          represent and warrant that you are 18 years or older; (3) acknowledge
          that you have read and understand the
          <a href="https://www.harryfox.com/#/terms-of-use" target="_blank"
            >Terms of Use</a
          >, including the
          <a href="https://www.harryfox.com/#/privacy-policy" target="_blank"
            >HFA Privacy Policy</a
          >; and (4) agree that you and any organization you represent will be
          bound by all terms and conditions contained in the
          <a href="https://www.harryfox.com/#/terms-of-use" target="_blank"
            >Terms of Use</a
          >.
        </p>

        <div class="buttons">
          <div class="buttons">
            <md-button class="md-dense" type="submit" @click="runSearch">
              Search
            </md-button>
            <a class="search-tips" @click="showDialog = true">Search Tips</a>
          </div>
        </div>
      </div>
    </div>

    <div id="search-results" class="content" v-show="results.length">
      <p>
        Your search for "{{ yourSearch }}" returned {{ totalResults }} results.
        Only 10 are displayed.
        <router-link :to="{ name: 'sign-up' }">Register</router-link> or
        <a @click="login">Login</a> for full access.
      </p>

      <md-table v-model="results">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Song Code">
            {{ item.song_code }}
          </md-table-cell>

          <md-table-cell md-label="Song Title">
            {{ item.title }}
          </md-table-cell>

          <md-table-cell md-label="Songwriters">
            {{ item.writer }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>

    <md-dialog :md-active.sync="showDialog" class="search-tips-dialog">
      <md-dialog-title>
        TIPS ON SEARCHING SONGFILE
        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showDialog = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <p>
          Songfile utilizes a "fuzzy logic" search engine to locate songs in the
          database. You do not need to use wildcards (*, ?) or boolean terms
          ("and", "or", etc).
        </p>

        <p>
          We suggest you search by song title first, then add songwriter name if
          necessary. Song search results are ranked in the order of song
          popularity to help you identify the right song.
        </p>

        <p>
          Using the name of the writer who is part of a group or uses an alias
          may not yield positive search results. For example, if a song was
          registered with HFA as being written by Sean Combs, a writer search
          using any of his aliases such as Puff Daddy or P. Diddy will not
          return that song.
        </p>

        <p>
          Note that the performer or artist that recorded the song may not be
          the songwriter. You may wish to research writer information at
          <a href="http://www.ascap.com" target="_blank">www.ascap.com</a>,
          <a href="http://www.bmi.com" target="_blank">www.bmi.com</a>, or
          <a href="http://www.sesac.com" target="_blank">www.sesac.com</a>
        </p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary md-raised" @click="showDialog = false">
          <md-icon>arrow_left</md-icon>
          Back to Song Search
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PublicSearch",
  metaInfo: function() {
    return {
      title: " - Song Search",
      meta: [
        {
          name: "description",
          content: "Search for songs."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      showDialog: false,
      search: {
        title: "",
        writer: ""
      }
    };
  },
  computed: {
    ...mapGetters("search", ["yourSearch", "totalResults"]),
    results: function() {
      if (this.$store.state.search.results.data == undefined) {
        return [];
      } else {
        return this.$store.state.search.results.data;
      }
    }
  },
  methods: {
    runSearch: function() {
      this.$store
        .dispatch("search/searchSongs", { ...this.search })
        .then(() => {
          document.getElementById("search-results").scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        });
    },
    login: function() {
      this.$emit("showLogin");
    }
  },
  beforeDestroy: function() {
    this.$store.dispatch("search/emptyResults");
  }
};
</script>

<style lang="scss">
#public-search {
  .header {
    background-color: $green;

    > div {
      margin: 0 auto;
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      h1 {
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
      }
      .md-field {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        width: 345px;

        label {
          color: #fff;
          font-weight: bold;
          font-size: 1.2em;
          margin-bottom: 10px;
        }
        .md-input {
          font-size: 1.2em;
          border: 0;
          width: 100%;
        }
      }

      .legal {
        padding: 0 20px;
        font-size: 1em;

        a {
          color: black;
          text-decoration: underline;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .md-button {
          color: #fff;
          margin-right: 20px;
        }
        .search-tips {
          color: #fff;
          font-weight: bold;
          text-decoration: underline;
          display: inline-block;
          padding: 10px 0;
        }
      }
    }
  }

  .content {
    width: 70vw;
    margin: 0 auto;
    padding: 30px 0 0;

    .md-table {
      .md-table-head-label {
        font-size: 1.4em;
        color: #000;
      }
      .md-table-cell-container {
        font-size: 1.2em;
      }
    }
  }
}
</style>
